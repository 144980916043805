<template>
<p>callbackpage...</p>
</template>

<script>
import {GRAPH} from "@/axios.js"
import {HTTP} from "@/axios.js"
// import Auth from '../../services/Auth'
import Auth from "@/services/Auth"
const CryptoJS = require("crypto-js")

export default {
  name: 'Callback',
  data(){
    return {
      adminList:['nuttapong.pongkam@cmu.ac.th','thapakorn.pe@cmu.ac.th']
    }
  },
  created() {
      console.log(this.$route.query.code !== undefined)
      // localStorage.setItem('code', this.$route.query.code)
      // this.getUserInGraph(this.$route.query.code)space
      this.filterUser(this.$route.query.code)
      // this.getToken()
      // this.getGraphToken()
  },
  methods: {
    async filterUser(queryCode){
      if(localStorage.getItem('filterShowFuture') === null){
        // add filter show futre defualt
        localStorage.setItem('filterShowFuture', true)
      }
      let access_token = await Auth.getTokenFromBe(process.env.VUE_APP_REDIRECT_URI, queryCode)
      console.log("filterUser",access_token)
      if(access_token !== null){
        console.log("AccessToken",access_token)
        let user = await Auth.getUserInGraph(access_token.access_token)
        console.log("🚀 ~ file: Callback.vue ~ line 34 ~ filterUser ~ user", user instanceof Error)
        if(!(user instanceof Error)){
          const accessToken_encrypt = CryptoJS.AES.encrypt(access_token.access_token, process.env.VUE_APP_CRYPTO_KEY).toString()
          localStorage.setItem('access_token', accessToken_encrypt)
          console.log("🚀 ~ file: Callback.vue ~ line 30 ~ filterUser ~ user", user)
          console.log("stdEventId",localStorage.getItem('stdEventId'))
          if(user){
            console.log("user:",user.mail)
            const userJobTitle_encrypt = CryptoJS.AES.encrypt(user.jobTitle, process.env.VUE_APP_CRYPTO_KEY).toString()
            if(this.adminList.includes(user.mail)){
              localStorage.setItem('Admin', CryptoJS.AES.encrypt('admin', process.env.VUE_APP_CRYPTO_KEY).toString())
            }else{
              localStorage.setItem('Admin', CryptoJS.AES.encrypt('user', process.env.VUE_APP_CRYPTO_KEY).toString())
            }
            localStorage.setItem('email', user.mail)
            localStorage.setItem('name', user.displayName)
            localStorage.setItem('AccType', userJobTitle_encrypt)
            if(user.jobTitle !== "Employee"){
              this.$router.push({path: '/pages/nopermission'})
            }else{
              // this.$router.push({path: 'announcement'})
              const nextPath = `${localStorage.getItem("beforepath") ? localStorage.getItem("beforepath") : "announcement"}`
              console.log("nextPath" ,nextPath)
              localStorage.removeItem("beforepath")
              this.$router.push({ path: nextPath })
            }
          }else{
            console.log("user undefined")
            localStorage.removeItem('code')
            localStorage.removeItem('access_token')
            localStorage.removeItem('email')
            localStorage.removeItem('name')
            localStorage.removeItem('AccType')
            localStorage.removeItem('Admin')
            this.$router.push({path: '/pages/500'})
          }
        }
        
      }else{
        this.$router.push({path: '/pages/500'})
      }
    },
    async getStudentReportEachUser(email){
      var response
      await HTTP.get(`api/event/leave/user?eventid=${this.eventID}&email=${email}`)
      .then(res => {
        if (res.data) {
          let resData = JSON.parse(JSON.stringify(res.data))
          console.log("getStudentReportEachUser(API):",resData);
          response = resData
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
    async getGraphToken (code) {
      let response = await Auth.getTokenFromGraph(code)
    }
  }
}
</script>
